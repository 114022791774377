.displaygrid {
  display: flex;
  align-items: center;
  align-self: center;
}


.image-grid div {
  width: 150px;
  height: 300px;
  /* width: 50%; */
  /* height: 240px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  gap: 1rem;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
}


.cardContainer {
  display: grid;
  gap: 1rem;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: repeat(4, 1fr);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.grid-container .grid-item {
  display: grid;  
}

.styleCard {
    border-radius: 8px;
    box-shadow: 0px 0px 8px #ccc;
    color: #253b56;
    margin: 8px 5px;
  }
  
  .styleCardContent {
    padding-left: 4px;
    padding-right: px;
    /* padding: 4px 16px 20px 16px; */
  }
  
  .styleImage {
    background-color: lightGray;
    border-radius: 8px 8px 0 0;
    max-height: 150px;
    overflow: hidden;
  }
  
  .styleCardTitle {
    font-family: Avenir-Heavy;
    font-size: 20;
    font-weight: 600;
    margin: 12px 0px 0px 0px;
  }
  
  .styleLocationLabel {
    color: #8294aa;
    font-size: 14;
    margin: 4px 0;
  }
  
  .styleDescription {
    font-size: 14;
    margin: 8px 0 0 0;
  }
  