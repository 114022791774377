@media (min-width: 1025px) {
    .layout-wrapper {
        &.layout-overlay {
            .layout-topbar {
                left: 0;
            }

            .layout-main, .layout-footer {
                margin-left: 0;
            }

            &.layout-overlay-sidebar-active {
                .layout-sidebar {
                    left: 0;
                }

                .layout-topbar  {
                    left: 250px;
                }
            }

            &.layout-mobile-sidebar-active {
                .layout-sidebar {
                    left: 0;
                }

                .layout-topbar  {
                    left: 250px;
                }
            }
        }

        &.layout-static {
            .layout-topbar {
                left: 250px;
            }

            .layout-main, .layout-footer {
                margin-left: 250px;
            }

            &.layout-static-sidebar-inactive {
                .layout-topbar  {
                    left: 0;
                }

                .layout-main, .layout-footer {
                    margin-left: 0;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .layout-wrapper {
        .layout-topbar {
            left: 0;
        }

        .layout-main, .layout-footer {
            margin-left: 0;
        }

        .layout-sidebar {
            transition: left $transitionDuration;
            //left: -250px;  se comento esto porque no funcionaba.
            left: 0;
            margin-top: 50px;
        }

    }

    .body-overflow-hidden {
        overflow: hidden;
    }
}
